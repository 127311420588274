<template>
  <div amateur-qualifiers-result v-if="qualifiers || final">
    <div class="header">
      <LoldocupStarTitle class="title" theme="blue"><b>아마추어</b> 오픈 토너먼트</LoldocupStarTitle>
    </div>
    <div class="qualifiers" v-if="qualifiers">
      <p class="sub-title">
        - 예선 결과 안내 -
      </p>
      <PItem class="table">
        <table>
          <colgroup>
            <col width="25%">
            <col width="45%">
            <col width="30%">
          </colgroup>
          <thead>
            <tr>
              <th>순위</th>
              <th>팀명</th>
              <th>방송 일정</th>
            </tr>
            <tr class="margin">
              <th colspan="3" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowspan="8" class="rank">
                <span><em>본선 진출팀</em><br>예선 그룹별 1위</span>
              </td>
              <td class="team-name">토낀토끼</td>
              <td rowspan="8" class="result"><p>최종 순위는 <b>12/18(토)</b>에 진행되는 결승전 생방송을 통해 공개됩니다</p></td>
            </tr>
            <tr>
              <td class="team-name">Shadow Battlica</td>
            </tr>
            <tr>
              <td class="team-name">Rio</td>
            </tr>
            <tr>
              <td class="team-name">Shadow zero</td>
            </tr>
            <tr>
              <td class="team-name">미친템포맨</td>
            </tr>
            <tr>
              <td class="team-name">S.W.A.P</td>
            </tr>
            <tr>
              <td class="team-name">롤체기사들</td>
            </tr>
            <tr>
              <td class="team-name">호우주의</td>
            </tr>
          </tbody>
        </table>
      </PItem>
    </div>
    <div class="final" v-else-if="final">
      <p class="sub-title">
        - 본선 최종 결과 안내 -
      </p>
      <PItem class="table">
        <table>
          <colgroup>
            <col width="25%">
            <col width="45%">
            <col width="30%">
          </colgroup>
          <thead>
            <tr>
              <th>순위</th>
              <th>팀명</th>
              <th>순위보상</th>
            </tr>
            <tr class="margin">
              <th colspan="3" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="rank rank-1">
                <span>1위</span>
              </td>
              <td class="team-name">Rio</td>
              <td class="prize">1,000만원</td>
            </tr>
            <tr>
              <td class="rank rank-2">
                <span>2위</span>
              </td>
              <td class="team-name">호우주의</td>
              <td class="prize">500만원</td>
            </tr>
            <tr>
              <td rowspan="2" class="rank rank-3">
                <span>공동 3위</span>
              </td>
              <td class="team-name">S.W.A.P</td>
              <td rowspan="2" class="prize">각 150만원</td>
            </tr>
            <tr>
              <td class="team-name">롤체기사들</td>
            </tr>
            <tr>
              <td rowspan="2" class="rank rank-4">
                <span>공동 5위</span>
              </td>
              <td class="team-name">Shadow Zero</td>
              <td rowspan="2" class="prize">각 50만원</td>
            </tr>
            <tr>
              <td class="team-name">토낀토끼</td>
            </tr>
          </tbody>
        </table>
      </PItem>
    </div>

    <div class="detail">
      <p>그룹 별 16강에 진출한 모든 팀(총 128팀)에게 보상으로<br><em> 아리 패키지</em>가 증정됩니다.</p>
    </div>
  </div>
</template>

<script>
import LoldocupStarTitle from '@/views/components/loldocup/LoldocupStarTitle.vue';

export default {
  name: 'AmateurResult',
  components: { LoldocupStarTitle },
  props: {
    qualifiers: Boolean,
    final: Boolean,
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[amateur-qualifiers-result] { .p(0, 20); .bgc(white); .font-g; .tc; .rel; .mt(144);
  &:before { .cnt; .abs; .lt(0, -56); .wh(100%, 56); .cover('@{do-leaderboard}/m/BG_Shape_Top.svg'); .z(1);}
  &:after { .cnt; .abs; .lb(0, -56); .wh(100%, 56); .t-r(180deg); .cover('@{do-leaderboard}/m/BG_Shape_Top.svg'); .z(1);}
  .header { .wh(100%); .bg('@{do-leaderboard}/m/Title_back.svg'); .bg-c; .no-repeat; .bg-y(11);
    .title { .fs(26, 20); .ls(-2); .medium; .pb(20);
      > b { .bold;}
    }
  }
  .sub-title { .c(#4271c7); .fs(18, 26px); .ls(-1); .mt(16);}
  .table { .ani-base;
    .intro-up(1, {.ani-on});
  }
  table { .mt(16); .mb(32);
    thead { .fs(14, 14px); .tc; .c(white); .medium; .bgc(#1d399c);
      > tr {
        > th { .h(33); .-r(#3456d2, 1px); }
      }
      .margin {
        > th { .h(6); .bgc(white); .-r;}
      }
    }
    tbody { .tc; .bold; .fs(14, 14px);
      > tr { .h(56); .bgc(#f8f1f8);
        > td { .-r(#f0caf0, 1px); .-b(#f0caf0, 1px);
        }
      }
      .rank {
        > span { .c(#9274b5); .fs(12, 12px);.ls(-1); .medium; .rel;
          > em { .c(#7214e4); .fs(14, 20px); .ls(-2); .bold; }
          &:before { .abs; .cnt; .lt(50%, -27); .t-xc; .wh(15, 16); .contain('@{do-leaderboard}/Rank_1_star.svg'); }
        }
      }
      .team-name { .c(#4271c7);}
      .result { .fs(12, 12); .regular; .c(#240d55);
        > p { .max-w(75); .mh-c;}
      }
      .prize { .-r; .c(#240d55);}
    }
  }
  .detail { .wh(100%, 72); .pt(16); .tc; .fs(13, 20px); .ls(-1); .bgc(#f5f0ff); .c(#4271c7);
    > p { .rel; .max-w(345); .mh-c;
      &:before { .abs; .lt(15, 0); .cnt; .wh(11, 9); .contain('@{do-leaderboard}/Wording_L.svg'); }
      &:after { .abs; .rt(15, 0); .cnt; .wh(11, 9); .contain('@{do-leaderboard}/Wording_R.svg'); }
      > em { .c(#5e05c9);}
    }
  }

  .final {
    .rank { .bgc(#f3e6f3); .ls(-2);
      > span { .rel; .bold;
        &:before { .cnt; .wh(16); .abs; .lt(-17, -2); }
        &:after { .cnt; .wh(16); .abs; .rt(-25, -2);}
      }
      &-1 {
        > span { .c(#5e05c9);
          &:before {.contain('@{do-leaderboard}/Rank_1_star.svg');}
          &:after { .contain('@{do-leaderboard}/Rank_1_star.svg');}
        }
      }
      &-2 {
        > span { .c(#994ff3);
          &:before { .contain('@{do-leaderboard}/Rank_2_star.svg'); }
          &:after { .contain('@{do-leaderboard}/Rank_2_star.svg');}
        }
      }
      &-3 {
        > span { .c(#9274b5);
          &:before {.contain('@{do-leaderboard}/Rank_3_star.svg'); .lt(50%, -22); .t-xc;}
        }
      }
      &-4 {
        > span { .c(#8294d5);
          &:before { .contain('@{do-leaderboard}/Rank_4_star.svg'); .lt(50%, -22); .t-xc;}
        }
      }
    }
  }

  @media (@tl-up) {
    .mt(186);  .p(0, 100, 14, 100);
    &:before { .cover('@{do-leaderboard}/BG_Shape_Top.svg'); .h(66); .t(-66);}
    &:after { .cover('@{do-leaderboard}/BG_Shape_Top.svg'); .h(66); .b(-66); .t-r(180deg);}
    .header { .bg('@{do-leaderboard}/Title_BG.svg'); .h(74); .contain; .bg-c; .flex; justify-content: center; align-items: center;
      .title { .pb(0); .fs(32, 28);}
    }
    .sub-title { .fs(24);}
    table {.mt(17); .mb(40);
      thead { .fs(14, 14px); .tc; .c(white); .medium;
        .margin {
          > th { .h(12);}
        }
      }
      tbody { .fs(20, 20px);
        > tr { .h(60); .bgc(#f8f1f8);}
        .rank {
          > span { .fs(14, 14px);
            &:before { .wh(20); .lt(50%, -36); }
            > em {.fs(20, 20px); }
          }
        }
        .result { .fs(16, 16);
          > p { .max-w(160);}
        }
      }
    }
    .detail { .pt(20); .h(104); .fs(20, 32px); .ls(-1);
      > p { .max-w(480);
        &:before { .wh(12, 11); .lt(0, 2);}
        &:after { .wh(12, 11); .rt(0, 2);}
      }
    }

    .final {
      .rank {
        > span { .fs(20, 20px); .ls(-2);
          &:before { .wh(20); .t(0);}
          &:after { .wh(20); .rt(-28, 0);}
        }
        &-3 {
          > span {
            &:before { .wh(20); .t(-27); }
          }
        }
        &-4 {
          > span {
            &:before { .wh(20); .t(-27); }
          }
        }
      }
    }
  }
}

</style>
