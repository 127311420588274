<template>
  <PSection loldocup-result :class="{empty}">
    <div class="inner-holder">
      <SectionTitle title-ko="대회 결과" black sm />
      <div class="empty" v-if="empty">
        대회 결과는 스테이지 종료 후 업데이트 됩니다.
      </div>
      <div class="list-holder" v-else>
        <div class="balloons">
          <img class="balloon1" src="/img/pages/loldocup/leaderboard/Balloon_R.png" />
          <img v-if="qualifiers || final" class="balloon2" src="/img/pages/loldocup/leaderboard/Balloon_L.png" />
          <img v-if="qualifiers || final" class="balloon3" src="/img/pages/loldocup/leaderboard/m/Balloon_L.png" />
        </div>
        <influencerMatchResult />
        <AmateurResult :qualifiers="qualifiers" :final="final" />
      </div>
    </div>
  </PSection>
</template>

<script>
import meta from '@/data/meta';
import InfluencerMatchResult from '@/views/components/leaderboard/loldocup/InfluencerMatchResult.vue';
import AmateurResult from '@/views/components/leaderboard/loldocup/AmateurResult.vue';
import SectionTitle from '@/views/components/common/SectionTitle.vue';

export default {
  name: 'LoldocupResult',
  components: { SectionTitle, InfluencerMatchResult, AmateurResult },
  computed: {
    empty() {
      const todayTimeStamp = new Date().getTime();
      const startTimeStamp = new Date('2021-12-06').getTime();

      return todayTimeStamp < startTimeStamp;
    },
    qualifiers() {
      const todayTimeStamp = new Date().getTime();
      const startTimeStamp = new Date('2021-12-13').getTime();
      const endTimeStamp = new Date('2021-12-19').getTime();

      return startTimeStamp <= todayTimeStamp && todayTimeStamp <= endTimeStamp;
    },
    final() {
      const todayTimeStamp = new Date().getTime();
      const startTimeStamp = new Date('2021-12-20').getTime();

      return startTimeStamp <= todayTimeStamp;
    },
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[loldocup-result] { .bgc(white);
  &.empty {
    .inner-holder { .p(140, 20, 60);
    }
  }
  .inner-holder { .p(140, 0, 60);
    [section-title] {
      .ko {.fs(20, 20); .bold; .font-g; .ls(-1); .ml(20); .mb(20);}
    }
    .empty { .wh(100%, 600); .bgc(#ebebf0); .br(4); .items-center; .flex-middle; .c(rgba(0, 0, 0, .8));
      &:before { .cnt; .wh(60, 52); .contain('@{logo}/ico-riot-dark-gray.svg'); .mb(20);}
    }
    .list-holder { .bgc(#fbe2ff); .wh(100%); .p(112, 0); .rel;
      .balloons { .abs; .lt(0, 0); .w(100%); .z(2);
        .balloon1 { .abs; .wh(90, 161); .rt(-10, 550); .z(1); animation: dodongsil 3s 0.5s ease-in-out infinite;}
        .balloon2 { .hide; .abs; .wh(80, 150); .lt(20, 1100); .z(1); animation: dodongsil 3s 0.5s ease-in-out infinite;}
        .balloon3 { .abs; .wh(80, 122); .lt(20, 1300); .z(1); animation: dodongsil 3s 0.5s ease-in-out infinite;}
      }
    }
  }
  @media (@tl-up) {
    .inner-holder { .p(140, 58);
      [section-title] {
        .ko { .fs(32, 32); .ml(0); .mb(40);}
      }
      .list-holder { .wh(100%); .cover('@{do-leaderboard}/BG.jpg'); .no-repeat; .bg-c; .mh-c; .p(166, 64);
        .balloons {
          .balloon1 { .r(20); .wh(105, 188);}
          .balloon2 { .block;}
          .balloon3 { .hide;}
        }
      }
    }
  }
  @media (@ds-up) {
    .inner-holder { .p(140, 0); .w(@screen-ds-min); .mh-c;
      .list-holder { .max-w(1260); .w(100%);}
    }
  }
}
</style>
