<template>
  <div influencer-match-result>
    <div class="header">
      <LoldocupStarTitle class="title" theme="blue"><b>인플루언서</b> 도네이션 매치</LoldocupStarTitle>
    </div>

    <PItem class="table">
      <table>
        <colgroup>
          <col width="25%">
          <col width="45%">
          <col width="30%">
        </colgroup>
        <thead>
          <tr>
            <th>순위</th>
            <th>팀명</th>
            <th>순위보상</th>
          </tr>
          <tr class="margin">
            <th colspan="3" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="rank rank-1">
              <span>1위</span>
            </td>
            <td>갱기도 미남</td>
            <td>300만원</td>
          </tr>
          <tr>
            <td class="rank rank-2">
              <span>2위</span>
            </td>
            <td>막내가 말대꾸</td>
            <td>150만원</td>
          </tr>
          <tr>
            <td class="rank rank-3">
              <span>3위</span>
            </td>
            <td>십시일반</td>
            <td>100만원</td>
          </tr>
          <tr>
            <td class="rank rank-4">
              <span>4위</span>
            </td>
            <td>보여주나</td>
            <td>50만원</td>
          </tr>
        </tbody>
      </table>
    </PItem>

    <div class="detail">
      <p>3분 미션 1등 팀인 <em>십시일반 팀이</em> 인플루언서 대표로 <br><em>총 500만원</em>의 기부금을 전달합니다.</p>
    </div>
  </div>

</template>

<script>
import LoldocupStarTitle from '@/views/components/loldocup/LoldocupStarTitle.vue';

export default {
  name: 'InfluencerMatchResult',
  components: { LoldocupStarTitle },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[influencer-match-result] { .p(0, 20); .bgc(white); .font-g; .tc; .rel;
  &:before { .cnt; .abs; .lt(0, -56); .wh(100%, 56); .cover('@{do-leaderboard}/m/BG_Shape_Top.svg'); .z(1);}
  &:after { .cnt; .abs; .lb(0, -56); .wh(100%, 56); .t-r(180deg); .cover('@{do-leaderboard}/m/BG_Shape_Top.svg'); .z(1);}
  .header { .wh(100%); .bg('@{do-leaderboard}/m/Title_back.svg'); .bg-c; .no-repeat; .bg-y(11);
    .title { .pb(20); .fs(26, 20); .ls(-2); .medium;
      > b { .bold;}
    }
  }
  .table { .ani-base;
    .intro-up(1, {.ani-on});}
  table { .mt(25); .mb(33);
    thead { .fs(14, 14px); .tc; .medium; .bgc(#a47dd4); .c(white);
      > tr {
        > th { .h(33); .-r(#a15fb8, 1px);
          &:nth-last-of-type(1) { .-r;}
        }
      }
      .margin {
        > th {.bgc(white); .h(6); .-r;}
      }
    }
    tbody { .tc; .bold; .fs(14, 14px);
      .rank { .bgc(#f3e6f3); .lh(20);
        > span { .rel; .ls(-2);
          &:before { .cnt; .wh(16); .abs; .lt(-20, -2); }
          &:after { .cnt; .wh(16); .abs; .rt(-20, -2);}
        }
        &-1 { .c(#5e05c9);
          > span {
            &:before {.contain('@{do-leaderboard}/Rank_1_star.svg');}
            &:after { .contain('@{do-leaderboard}/Rank_1_star.svg');}
          }
        }
        &-2 { .c(#994ff3);
          > span {
            &:before { .contain('@{do-leaderboard}/Rank_2_star.svg'); }
            &:after { .contain('@{do-leaderboard}/Rank_2_star.svg');}
          }
        }
        &-3 { .c(#9274b5);
          > span {
            &:before {.contain('@{do-leaderboard}/Rank_3_star.svg');}
            &:after { .contain('@{do-leaderboard}/Rank_3_star.svg'); }
          }
        }
        &-4 { .c(#8294d5);
          > span {
            &:before { .contain('@{do-leaderboard}/Rank_4_star.svg');}
            &:after { .contain('@{do-leaderboard}/Rank_4_star.svg'); }
          }
        }
      }
      > tr { .h(56); .bgc(#f8f1f8);
        > td { .-r(#f0caf0, 1px); .-b(#f0caf0, 1px);
          &:nth-of-type(2n) { .c(#4271c7); }
          &:nth-of-type(3n) { .c(#240d55); .-r; }
        }
      }
    }
  }
  .detail { .pt(16); .wh(100%, 72); .tc; .fs(13, 20px); .bgc(#f5f0ff); .c(#4271c7); .ls(-1);
    > p { .rel; .max-w(345); .mh-c;
      &:before { .cnt; .abs; .lt(15, 0); .wh(11, 9); .contain('@{do-leaderboard}/Wording_L.svg'); }
      &:after { .cnt; .abs; .rt(15, 0); .wh(11, 9); .contain('@{do-leaderboard}/Wording_R.svg');}
      > em { .c(#5e05c9);}
    }
  }

  @media (@tl-up) {
    .p(0, 98, 14, 98);
    &:before { .cover('@{do-leaderboard}/BG_Shape_Top.svg'); .h(66); .t(-66);}
    &:after { .cover('@{do-leaderboard}/BG_Shape_Top.svg'); .h(66); .b(-66); .t-r(180deg);}
    .header { .bg('@{do-leaderboard}/Title_BG.svg'); .h(74); .contain; .bg-c; .flex; justify-content: center; align-items: center;
      .title { .pb(0); .fs(32, 28);}
    }
    table {.mt(32);
      thead { .fs(14, 14px); .tc; .c(white); .medium; .bgc(#a47dd4);
        .margin {
          > th { .h(12);}
        }
      }
      tbody { .fs(20, 20px);
        > tr { .h(60); .bgc(#f8f1f8);}
        .rank {
          > span {
            &:before { .wh(20); .l(-27);}
            &:after { .wh(20); .r(-27);}

          }
        }
      }
    }
    .detail { .h(104); .fs(20, 32px); .ls(-1); .pt(20);
      > p { .max-w(480);
        &:before { .wh(12, 11); .lt(0, 2);}
        &:after { .wh(12, 11); .rt(0, 2);}
      }
    }
  }

  @media (@ds-up) {

  }
}

</style>
